<section class="container featured-ads-section-container max-container-width">
  <h1>Elite</h1>
  <div class="elite-ads-content-holder">
    <div
      *ngFor="let featuredAd of featuredAds.elite"
      class="elite-ad-content-card-holder">
      <app-elite-ad-card [ad]="featuredAd"></app-elite-ad-card>
    </div>
  </div>
  <h1>Premium</h1>
  <div class="premium-ads-content-holder">
    <div
      *ngFor="let featuredAd of featuredAds.premium"
      class="premium-ad-content-card-holder">
      <app-premium-ad-card [ad]="featuredAd"></app-premium-ad-card>
    </div>
  </div>
  <h1>Standard</h1>
  <div class="standard-ads-content-holder">
    <div
      *ngFor="let featuredAd of featuredAds.standard"
      class="standard-ad-content-card-holder">
      <app-standard-ad-card [ad]="featuredAd"></app-standard-ad-card>
    </div>
  </div>
</section>
