<div class="footer-container">
  <div class="container footer-holder">
    <div class="footer-logo-holder">
      <img
        ngSrc="assets/talentmarket.logo.svg"
        alt="talentmarket."
        class="footer-logo"
        height="65"
        width="200" />
    </div>
    <div class="social-footer-holder">
      <i class="social-button facebook-button">
        <fa-icon [icon]="faFacebookF" (click)="navigateToFacebook()"></fa-icon>
      </i>
      <i class="social-button instagram-button">
        <fa-icon [icon]="faInstagram" (click)="navigateToInstagram()"></fa-icon>
      </i>
      <i class="social-button linkedin-button">
        <fa-icon [icon]="faLinkedinIn" (click)="navigateToLinkedIn()"></fa-icon>
      </i>
    </div>
    <div class="contact-holder">
      <div class="contact-content-holder">
        <fa-icon [icon]="faPhone"></fa-icon>
        <a [href]="'tel:' + '+385996989811'" aria-label="Make a phone call">
          099 6989 811
        </a>
      </div>
      <div class="contact-content-holder">
        <fa-icon [icon]="faEnvelope"></fa-icon>
        <p>info&#64;talentmarket.io</p>
      </div>
    </div>
    <div class="footer-navigation-holder">
      <div class="footer-navigation">
        <p (click)="navigateToTop()">Na početak</p>
        <p (click)="navigateToAboutUs()">O nama</p>
        <p (click)="navigateToContactUs()">Kontaktiraj nas</p>
      </div>
      <div class="footer-navigation">
        <p (click)="navigateToPrivacyPolicy()">Politika privatnosti</p>
      </div>
    </div>
  </div>
</div>
