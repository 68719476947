<div class="premium-ad-card">
  <div class="premium-ad-badge">Premium</div>
  <div class="premium-ad-logo-holder">
    <img
      class="premium-ad-logo"
      src="data:image/png;base64, {{ ad.companyLogo }}"
      alt="{{ ad.companyName }} logo" />
  </div>
  <div class="premium-ad-title-holder">
    <p>{{ ad.campaignTitle }}</p>
  </div>
  <div class="ad-card-location-holder">
    <span class="material-symbols-outlined"> location_on </span>
    {{ ad.jobLocation }}
  </div>
  <button
    class="apply-to-premium-campaign"
    (click)="navigateToCampaignLandingPage(ad.campaignUrlId)">
    Brza prijava
  </button>
</div>
