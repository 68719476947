import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { adminGuard, userGuard } from './login/auth.guard';
import { UserComponent } from './applicant/applicant-quiz/user/user.component';
import { LoginComponent } from './login/login/login.component';
import { PasswordChangeComponent } from './login/password-change/password-change.component';
import { TermsAndConditionsPageComponent } from './applicant/applicant-quiz/terms-and-conditions-page/terms-and-conditions-page.component';
import { PolicyPrivacyPageComponent } from './applicant/applicant-quiz/policy-privacy-page/policy-privacy-page.component';
import { CampaignLandingPageComponent } from './applicant/applicant-quiz/campaign-landing-page/campaign-landing-page.component';
import { PublicComponent } from './public/public.component';
import { HomePageComponent } from './public/home-page/home-page.component';
import { ContactUsPageComponent } from './public/contact-us-page/contact-us-page.component';
import { ThankYouForContactPageComponent } from './public/contact-us-page/thank-you-for-contact-page/thank-you-for-contact-page.component';
import { AboutUsPageComponent } from './public/about-us-page/about-us-page.component';
import { PrivacyPolicyPageComponent } from './public/privacy-policy-page/privacy-policy-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: 'home',
        component: HomePageComponent,
      },
      {
        path: 'about-us',
        component: AboutUsPageComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsPageComponent,
      },
      {
        path: 'thank-you-for-contact-us',
        component: ThankYouForContactPageComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyPageComponent,
      },
    ],
  },
  { path: 'signin', component: LoginComponent },
  { path: 'password-change', component: PasswordChangeComponent },
  {
    canActivate: [adminGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    path: 'admin',
  },
  {
    canActivate: [userGuard],
    loadChildren: () =>
      import('./client/client.module').then(m => m.ClientModule),
    path: 'dashboard',
  },
  // {
  //   path: 'public',
  //   component: PublicComponent,
  // },
  {
    path: 'clp/:urlId',
    component: CampaignLandingPageComponent,
  },
  { path: 'q/:urlId', component: UserComponent },
  { path: 'privacy-policy/:urlId', component: PolicyPrivacyPageComponent },
  {
    path: 'terms-and-conditions/:urlId',
    component: TermsAndConditionsPageComponent,
  },
  // TODO page-not-found handling
  // { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'signin' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
