<div
  class="elite-ad-card"
  [ngStyle]="{ '--company-color': setRGBColor(ad.companyColor) }">
  <div class="elite-ad-badge">Elite</div>
  <img
    class="elite-ad-logo"
    src="data:image/png;base64, {{ ad.companyLogo }}"
    alt="{{ ad.companyName }} logo" />
  <div class="ad-card-holder">
    <p class="ad-card-campaign-title">{{ ad.campaignTitle }}</p>
    <div class="ad-card-location-holder">
      <span class="material-symbols-outlined"> location_on </span>
      {{ ad.jobLocation }}
    </div>
  </div>
  <button
    class="apply-to-campaign"
    (click)="navigateToCampaignLandingPage(ad.campaignUrlId)">
    Brza prijava
  </button>
</div>
