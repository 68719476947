<section class="container about-us-section-container max-container-width">
  <h1 class="about-us-title">Zašto talentmarket.?</h1>
  <div class="why-talentmarket-container">
    <div class="why-talentmarket-card">
      <div class="about-us-talentmarket-logo-holder">
        <img
          src="assets/talentmarket.logo.svg"
          class="about-us-talentmarket-logo"
          alt="Talentmarket logo" />
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-green-check-mark.svg" alt="Check mark" />
        <p>Brza prijava kandidata (< 1 min)</p>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-green-check-mark.svg" alt="Check mark" />
        <p>5-10x više kvalitetnih prijava</p>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-green-check-mark.svg" alt="Check mark" />
        <p>Sustav koji automatski ocjenjuje kandidate</p>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-green-check-mark.svg" alt="Check mark" />
        <p>talentmarket. pristup “ključ u ruke”</p>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-green-check-mark.svg" alt="Check mark" />
        <p>Podrška 24/7</p>
      </div>
    </div>
    <div
      class="why-talentmarket-card"
      style="background-color: var(--white-color)">
      <div class="why-card-title-holder">
        <h2 class="why-card-title">Drugi oglasnici za posao</h2>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-red-check.svg" alt="X mark" />
        <p>Dug proces koji rezultira gubitkom kandidata</p>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-red-check.svg" alt="X mark" />
        <p>Nezadovoljavajući broj i kvaliteta prijava</p>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-red-check.svg" alt="X mark" />
        <p>Lista kandidata bez pretkvalifikacije (ocjena)</p>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-red-check.svg" alt="X mark" />
        <p>Nepostojeća pomoć u daljnjem procesu</p>
      </div>
      <div class="why-talentmarket-fact-card">
        <img src="assets/about-us-red-check.svg" alt="X mark" />
        <p>Ograničena podrška</p>
      </div>
    </div>
  </div>
  <div class="job-providers-container">
    <h2 class="about-us-subtitle">180+ poslodavaca u 8 zemalja</h2>
    <img
      src="assets/about-us-graphic.png"
      class="about-us-job-search-graphic"
      alt="Job search graphic"
      aria-disabled="true" />
  </div>
  <div class="hire-now-container">
    <h2 class="about-us-subtitle">
      Kreni sa zapošljavanjem - <br />
      pošalji upit ili nas nazovi odmah
    </h2>
    <div class="contact-buttons-holder">
      <button
        class="basic-black-button"
        aria-label="Send inquiry"
        (click)="navigateToContactUs()">
        <fa-icon [icon]="faPaperPlane"></fa-icon>&nbsp; Pošalji upit
      </button>
      <a
        class="basic-black-button basic-black-button-phone-link"
        [href]="'tel:' + '+385996989811'"
        aria-label="Make a phone call">
        <fa-icon [icon]="faPhoneAlt"></fa-icon>&nbsp; 099 6989 811
      </a>
    </div>
    <img
      src="assets/about-us-main-graphic.svg"
      class="about-us-main-graphic"
      alt="Two business men shaking hand " />
  </div>
  <div class="wanted-ads-container">
    <h2 class="about-us-subtitle">
      Oglasnik koji kandidati žele koristiti je oglasnik kojim su poslodavci
      zadovoljni.
    </h2>
    <p>
      <strong>talentmarket.</strong> spaja interese poslodavca i kandidata. Bez
      kompromisa.
    </p>
    <p>
      Dokazani pristup koji brzinom i efikasnošću dovodi zadovoljstvo poslodavcu
      i kandidatu.
    </p>
    <img
      src="assets/about-us-underline-graphic.png"
      class="about-us-underline-graphic"
      alt="Underline graphic"
      aria-disabled="true" />
  </div>
  <div class="add-job-listing-container">
    <div class="add-job-card">
      <h2 class="about-us-subtitle">Postavi oglas i zaposli unutar 5 dana</h2>
      <button
        class="basic-black-button"
        aria-label="Send inquiry"
        (click)="navigateToContactUs()">
        <fa-icon [icon]="faPaperPlane"></fa-icon>&nbsp; Pošalji upit
      </button>
    </div>
  </div>
  <div class="our-partners-container">
    <h2 class="our-partners-title">Naši partneri</h2>
    <div class="partner-holder">
      <div class="partner-text">
        <h3 class="partner-subtitle">IT Development i podrška</h3>
        <p>
          <strong>SIGIT Agency</strong> je inovativna IT tvrtka sa sjedištem u
          Hrvatskoj, osnovana 2020. godine – u vremenu ubrzane digitalizacije
          koja je transformirala poslovni svijet. <br />
          <br />
          Kao odgovor na rastuću potražnju za agilnim, brzim i fleksibilnim IT
          rješenjima, pokrenut je SIGIT Agency s misijom da postanu ključni
          partner u digitalizaciji i unapređenju softverskih sustava, bilo kroz
          stvaranje novih rješenja ili poboljšanje postojećih.
        </p>
      </div>
      <div class="partner-logo-holder">
        <img
          src="assets/partner-sigit-logo.svg"
          class="partner-logo"
          alt="Sigit partner company logo" />
      </div>
    </div>
    <div class="partner-holder">
      <div class="partner-text">
        <h3 class="partner-subtitle">Sigurnost podataka i propisi GDPR-a</h3>
        <p>
          <strong>WERXE</strong> je inovativna IT tvrtka koja surađuje s
          klijentima na izgradnji digitalnih rješenja za sigurnu, privatnu i
          uspješnu budućnost. <br />
          <br />
          Specijalizirani su za stvaranje digitalnih rješenja usmjerenih na
          privatnost koja omogućuju tvrtkama da napreduju u današnjem digitalnom
          krajoliku koji se brzo razvija. <br />
          <br />
          Usredotočujući se na inovacije, suradnju i izvrsnost, pomažu
          organizacijama da se prilagode i istaknu se u sve konkurentnijem
          svijetu koji je svjesniji privatnosti.
        </p>
      </div>
      <div class="partner-logo-holder">
        <img
          src="assets/partner-werxe-logo.svg"
          class="partner-logo"
          alt="Werxe partner company logo" />
      </div>
    </div>
  </div>
  <div class="call-us-container">
    <h2 class="about-us-subtitle">Nazovi nas odmah</h2>
    <a
      class="basic-black-button basic-black-button-phone-link"
      [href]="'tel:' + '+385996989811'"
      aria-label="Make a phone call">
      <fa-icon [icon]="faPhoneAlt"></fa-icon>&nbsp; 099 6989 811
    </a>
  </div>
</section>
<div class="clients-container">
  <picture>
    <source
      media="(min-width: 600px)"
      srcset="assets/about-us-clients-desktop.svg" />
    <img
      class="clients-image"
      srcset="assets/about-us-clients-mobile.svg"
      alt="Talentmarket. clients" />
  </picture>
</div>
<div class="about-us-footer-container">
  talentmarket d.o.o., Črnile 18, 42223 Črnile, Hrvatska - OIB: 42714275116
  Upisan u registar trgovačkog suda u Varaždinu
  <br />
  MBS: 05858062 - IBAN: HR5924840081135359358 pri RAIFFEISENBANK AUSTRIA d.d. -
  Temeljni kapital 2.500 EUR upisan u cijelosti
</div>
