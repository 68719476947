import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ContactUsDataModel } from '../models/contact-us-data.model';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  private apiServerUrl: string = environment.apiBaseUrl;
  constructor(
    private http: HttpClient,
    private router: Router
  ) {}
  public sendContactMail(contactUsFormData: ContactUsDataModel): void {
    this.http
      .post<ContactUsDataModel>(`${this.apiServerUrl}/contact-us`, {
        whatPosition: contactUsFormData.whatPosition,
        whatLocation: contactUsFormData.whatLocation,
        numberOfWorkers: contactUsFormData.numberOfWorkers,
        message: contactUsFormData.message,
        firstName: contactUsFormData.firstName,
        lastName: contactUsFormData.lastName,
        contactNumber: contactUsFormData.contactNumber,
        companyName: contactUsFormData.companyName,
        contactMail: contactUsFormData.contactMail,
      })
      .pipe(
        catchError(err => this.handleError(err)),
        tap((): void => {
          this.router.navigate(['thank-you-for-contact-us']).then();
        })
      )
      .subscribe();
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    const errorMessage = `Error: ${error.error.message}`;
    alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
