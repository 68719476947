import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public faFacebookF: IconDefinition = faFacebookF;
  public faInstagram: IconDefinition = faInstagram;
  public faLinkedinIn: IconDefinition = faLinkedinIn;
  public faPhone: IconDefinition = faPhoneAlt;
  public faEnvelope: IconDefinition = faEnvelope;

  constructor(private router: Router) {}
  public navigateToFacebook(): void {
    window.open('https://www.facebook.com/talentmarket.io', '_blank');
  }
  public navigateToInstagram(): void {
    window.open('https://www.facebook.com/talentmarket.io', '_blank');
  }
  public navigateToLinkedIn(): void {
    window.open('https://www.linkedin.com/company/talentmarketdot/', '_blank');
  }
  public navigateToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  public navigateToAboutUs(): void {
    this.router.navigate(['/about-us']).then();
  }
  public navigateToContactUs(): void {
    this.router.navigate(['/contact-us']).then();
  }
  public navigateToPrivacyPolicy(): void {
    this.router.navigate(['/privacy-policy']).then();
  }
}
