<section class="container privacy-policy-section-container max-container-width">
  <div class="privacy-policy-container">
    <div class="partner-text">
      <h2 class="privacy-policy-title">
        Politika privatnosti za talentmarket.io
      </h2>
      <p>Ažurirano: 18.12.2024.</p>
      <p>
        Ova Politika privatnosti objašnjava kako talentmarket d.o.o. ("mi",
        "naša tvrtka") prikuplja, koristi i štiti osobne podatke korisnika koji
        se prijavljuju na oglase za posao putem web stranice talentmarket.io.
      </p>
      <p>
        <strong>
          1. Voditelj obrade podataka
          <br />
          Talentmarket d.o.o.
        </strong>
      </p>
      <p>Adresa: Črnile 18, Varaždinske Toplice 42223, Hrvatska</p>
      <p>Email: info&#64;talentmarket.io</p>
      <p>
        U skladu s
        <strong>Općom uredbom o zaštiti podataka (GDPR),</strong> talentmarket
        d.o.o. djeluje kao voditelj obrade osobnih podataka za opće korištenje
        oglasnika.
      </p>
      <p>
        <strong> 2. Vrste osobnih podataka koje prikupljamo </strong>
      </p>
      <p>
        Prilikom prijave na oglase za posao putem našeg sustava, prikupljamo
        sljedeće podatke:
      </p>
      <ul>
        <li>Ime i prezime</li>
        <li>Broj mobitela (kontakt telefon)</li>
        <li>Adresa stanovanja</li>
        <li>Email adresa</li>
        <li>
          Odgovori na pitanja iz kvizova generiranih od strane tvrtki oglašivača
        </li>
        <li>
          Podaci dobiveni prijavom putem Google računa (ime, email adresa i
          drugi relevantni podaci)
        </li>
      </ul>
      <p>
        <strong> 3. Svrha i pravna osnova za obradu podataka </strong>
      </p>
      <p>Vaše osobne podatke prikupljamo i obrađujemo u sljedeće svrhe:</p>
      <ul>
        <li>
          <strong>Prijava na oglase za posao</strong>
          – Omogućavanje tvrtkama oglašivačima da vas kontaktiraju vezano za
          otvorene pozicije.
        </li>
        <li>
          <strong>Evaluacija prijava putem kvizova</strong>
          – Prosljeđivanje vaših odgovora tvrtkama radi selekcijskog procesa.
        </li>
        <li>
          <strong>Tehnička podrška</strong>
          – Odgovaranje na vaša pitanja i pružanje tehničke podrške u vezi
          korištenja platforme.
        </li>
        <li>
          <strong>Usklađenost s pravnim obvezama</strong>
          – Ispunjavanje pravnih i regulatornih obveza.
        </li>
      </ul>
      <p>
        Pravna osnova za obradu podataka temelji se na
        <strong>članku 6(1)</strong> GDPR-a:
      </p>
      <ul>
        <li>
          <strong>Privola</strong>
          (prilikom prijave na oglas ili prijave putem Google računa).
        </li>
        <li>
          <strong>Ispunjavanje ugovornih obveza</strong>
          između vas i tvrtke oglašivača.
        </li>
        <li>
          <strong>Legitimni interes</strong>
          za poboljšanje usluge i korisničkog iskustva.
        </li>
        <li>
          <strong>Usklađenost s pravnim obvezama</strong>
          – Ispunjavanje pravnih i regulatornih obveza.
        </li>
      </ul>
      <p>
        <strong> 4. Prijenos i dijeljenje podataka </strong>
      </p>
      <p>Vaši podaci mogu biti proslijeđeni:</p>
      <ul>
        <li>
          <strong>Tvrtkama oglašivačima</strong>
          – Vaši podaci i odgovori na kvizove dijele se isključivo s tvrtkama
          koje su objavile specifične oglase na koje ste se prijavili.
        </li>
        <li>
          <strong>Pružateljima tehničkih usluga</strong>
          – Trećim stranama koje održavaju našu platformu uz stroge mjere
          povjerljivosti i sigurnosti podataka.
        </li>
      </ul>
      <p>
        <strong>Važno:</strong> Talentmarket d.o.o. nije odgovoran za daljnju
        obradu podataka koju provode tvrtke oglašivači. Svaka tvrtka ima
        vlastitu politiku privatnosti.
      </p>
      <p>
        <strong> 5. Razdoblje čuvanja podataka </strong>
      </p>
      <p>
        Vaše osobne podatke čuvamo samo onoliko dugo koliko je potrebno za
        postizanje svrhe obrade:
      </p>
      <ul>
        <li>
          Podaci povezani s prijavama čuvaju se dok traje aktivnost oglasa ili
          dok je to nužno za tvrtke oglašivače.
        </li>
        <li>
          Anonimizirani podaci mogu se koristiti u statističke ili analitičke
          svrhe bez vremenskog ograničenja.
        </li>
        <li>
          Podaci prikupljeni putem Google prijave čuvaju se dok ne zatražite
          njihovo brisanje.
        </li>
      </ul>
      <p>
        <strong> 6. Prava korisnika </strong>
      </p>
      <p>U skladu s GDPR-om imate sljedeća prava:</p>
      <ul>
        <li>
          <strong>Pravo na pristup</strong>
          – Možete zatražiti kopiju svojih osobnih podataka koje obrađujemo.
        </li>
        <li>
          <strong>Pravo na ispravak</strong>
          – Ako su vaši podaci netočni ili nepotpuni, imate pravo zahtijevati
          ispravak.
        </li>
        <li>
          <strong>Pravo na brisanje (pravo na zaborav)</strong>
          – Možete zatražiti brisanje svojih podataka ako ne postoji pravna
          osnova za daljnju obradu.
        </li>
        <li>
          <strong>Pravo na prigovor</strong>
          – Imate pravo uložiti prigovor na obradu podataka temeljem legitimnog
          interesa.
        </li>
        <li>
          <strong>Pravo na prijenos podataka</strong>
          – Imate pravo zatražiti prijenos svojih podataka drugom voditelju
          obrade.
        </li>
        <li>
          <strong>Pravo na povlačenje privole</strong>
          – U svakom trenutku možete povući privolu bez negativnih posljedica.
        </li>
      </ul>
      <p>
        Za ostvarenje ovih prava obratite nam se na info&#64;talentmarket.io.
      </p>
      <p>
        <strong> 7. Sigurnost podataka </strong>
      </p>
      <p>
        Talentmarket d.o.o. primjenjuje odgovarajuće tehničke i organizacijske
        mjere kako bi zaštitio vaše osobne podatke od neovlaštenog pristupa,
        izmjene, gubitka ili zlouporabe.
      </p>
      <p>
        <strong> 8. Kontakt i pritužbe </strong>
      </p>
      <p>
        Za sva pitanja u vezi ove Politike privatnosti ili ostvarivanja vaših
        prava:
      </p>
      <ul>
        <li>
          <p>Email: info&#64;talentmarket.io</p>
        </li>
        <li>
          <p>Adresa: Črnile 18, Varaždinske Toplice 42223, Hrvatska</p>
        </li>
      </ul>
      <p>
        Ako smatrate da je došlo do povrede vaših prava, imate pravo podnijeti
        pritužbu nadležnoj Agenciji za zaštitu osobnih podataka (AZOP).
        <strong>talentmarket d.o.o.</strong> zadržava pravo izmjene ove Politike
        privatnosti. Sve izmjene bit će pravovremeno objavljene na web stranici
        <strong>talentmarket.io</strong> s datumom posljednjeg ažuriranja.
      </p>
    </div>
  </div>
</section>
